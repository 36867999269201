<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="64" alt="logo"/>
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back!</h4>
                        <p class="text-muted">Sign in to continue to VRsteps therapy</p>
                      </div>

                      <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                      >{{ notification.message }}
                      </b-alert>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn" @change="formChange">
                          <div class="error-message" v-if="authError">
                            <p>{{ authError }}</p>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="user">User</label>
                            <input
                                type="text"
                                v-model="email"
                                class="form-control"
                                id="user"
                                placeholder="Enter your username"
                                :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">User is required.</span>
                              <span v-if="!$v.email.email">Please enter valid user.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Password</label>
                            <input
                                v-model="password"
                                type="password"
                                class="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                                v-if="submitted && !$v.password.required"
                                class="invalid-feedback"
                            >Password is required.
                            </div>
                          </div>
                          <div class="custom-control">
                            <p>{{ $t('pages.login.session_terminate') }}</p>
                          </div>
                          <!--                          <div class="custom-control custom-checkbox">-->
                          <!--                            <input-->
                          <!--                                type="checkbox"-->
                          <!--                                class="custom-control-input"-->
                          <!--                                id="customControlInline"-->
                          <!--                            />-->
                          <!--                            <label-->
                          <!--                                class="custom-control-label"-->
                          <!--                                for="customControlInline"-->
                          <!--                            >Remember me</label>-->
                          <!--                          </div>-->

                          <div class="mt-4 text-center">
                            <button
                                class="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                            >Sign In
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <!--                        <p>-->
                        <!--                          Don't have an account ?-->
                        <!--                          <router-link-->
                        <!--                              tag="a"-->
                        <!--                              to="/register"-->
                        <!--                              class="font-weight-medium text-primary"-->
                        <!--                          >Register-->
                        <!--                          </router-link>-->
                        <!--                        </p>-->
                        <p>
                          © {{ new Date().getFullYear() }} VRsteps
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {required, email} from 'vuelidate/lib/validators'
import {v4 as uuidv4} from 'uuid'

import {
  monitorMethods,
  userMethods
} from '@/state/helpers'
import {getApi} from '@/api'

export default {
  data() {
    return {
      email: '',
      password: '',
      uuid: '',
      submitted: false,
      authError: ''
    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null
    }
  },
  created() {
    this.uuid = uuidv4()
    document.body.classList.add('auth-body-bg')
    // this.testUser()
  },
  validations: {
    email: {
      required,
      email
    },
    password: {required}
  },
  methods: {
    ...getApi(),
    ...userMethods,
    ...monitorMethods,
    tryToLogIn() {
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.tryingToLogIn = true
        // Reset the authError if it existed.
        this.authError = null
        return (
            this.loginUser(this.email, this.password, this.uuid).then(response => response.json())
                .then(d1 => {
                  if (d1.status) {
                    sessionStorage.setItem('accessToken', d1.token)
                    sessionStorage.setItem('userId', d1.user_id)
                    this.getUser(d1.user_id, d1.token, this.uuid).then(response => response.json()).then(u => {

                      if (u.user.image) u.user.image += '?unity=true'
                      sessionStorage.setItem('userType', u.user.type)
                      sessionStorage.setItem('user', JSON.stringify(u.user))
                      this.setToken({user: {...d1, user_type: u.user.type}})
                      this.setUser({user: u.user})
                      this.setMessages({messages: u.messages})
                      this.setFeatures({features: u.features})
                      // Redirect to the originally requested page, or to the home page
                      this.$router.push(
                          this.$route.query.redirectFrom || {name: 'home'}
                      )
                    })
                  } else {
                    this.authError = d1.message ? d1.message : ''
                  }
                }).catch(error => {
              this.authError = error ? error : ''
            })
        )
      }
    },
    formChange() {
      this.authError = ''
    }
  }
}
</script>
